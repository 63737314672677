@media (max-width: 600px) {
    .textContainer{
        padding: 30px 10px !important;
    }
}

.textContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 50px 100px;
    border-radius: 8px;
    max-width: 100%;
}

.color {
    background-color: var(--azul);
    color: white;
}