#galery {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
}
#galery .title{
    font-size: 40px;
    font-weight: 500;
    color: var(--azul);
    text-align: center;
}
#galery .imgsContainer{
    width: 355px;
}
#galery .galeryImage {
    width: 400px;
    position: relative;
}

#galery .galeryCarousel{
    margin: 10px 0 50px 0;
}

.slide{
    display: flex ;
    align-items: center;
    justify-content: center ;
}

