@media (max-width: 600px) {
    #header .mainName{
        display: none;
    }
    #header{
        justify-content: center !important;
    }
}

#header{
    position: absolute;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 5px 0;
    z-index: 1;
}

#header .text {
    text-align: center;
    color: white;
}

#home {
    position: relative;
    min-height: 100vh;
    background-image: url('../../imgs/panel1.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#home .logo {
    width: 600px;
    max-width: 90%;
    padding: 10px;
}
#home .slogan1{
    color: white;
    margin-bottom: 20px;
}
#home .slogan2 {
    margin: 20px;
    color: white;
    font-size: 25px;
    font-weight: 500;
    text-shadow: 1px 1px 5px black;
    text-align: center;
}

.btn {
    background-color: var(--verde);
    padding: 15px;
    border-radius: 5px;
    color: var(--azul);
    cursor: pointer;
    border: 3px solid transparent;
    font-weight: 500;
    transition: all 0.3s;
    width: max-content;
    height: max-content;
}

.btn:hover {
    background-color: var(--azul);
    text-shadow: 1px 1px 5px black;
    color: var(--verde);
    border: 3px solid var(--verde);
}

.whatsLink{
    text-decoration: none;
    color: black;
}

#home .fab{
    width: 50px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
}

#home .gradient{
    width: 100%;
    min-height: 100vh;
    position: absolute;
    background-image:  linear-gradient(290deg, var(--azul) 30%, transparent 100%)
}

#home .logoContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}