.middleText{
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    max-width: 800px !important;
    text-align: justify;
    line-height: 25px;
    align-self: center;
    white-space: pre-line;
}

@media (max-width: 600px) {
    .middleText + .middleText{
        text-align: center;
    }
}
