#copyPage {
    height: max-content;
}
@media (max-width: 700px) {
    #copyPage {
        height: max-content;
    }
    #copyPage .cardsContainer{
        flex-direction: column;
    }
}
#copyPage .cardsContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-wrap: wrap;
    margin: 40px 0;
}
#copyPage .icon{
    width: 100px;
}

#copyPage .card {
    width: 230px;
    height: 300px;

    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 10px;
    box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.164);

    margin: 40px;
    padding: 20px 10px 15px;
    text-align: center;
}
#copyPage .cardText{
    margin-top: 20px;
    white-space: pre-line;
    color: var(--azul);
    font-weight: 500;

}