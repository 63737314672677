*{
    scroll-behavior: smooth;
}
.textContainer{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    padding: 0 50px;
    gap: 30px;
}

.textContainer img{
    max-width: 100vw;
}

.iconsContainer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 70%;
    gap: 100px;
}

.logo {
    padding: 20px;
    width: 600px;
    background-color: var(--azul);
    border-radius: 12px;
}

@media (max-width: 600px) {
    .logo {
        max-width: 100%;
    }    
}

@media (max-width: 1000px) {
    .logo {
        margin: 50px 0;
    }    
}