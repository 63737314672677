.button {
    border: none;
    width: fit-content;
    align-self: center;
    padding: 15px 30px;
    background-image:  linear-gradient(290deg, var(--azul) 30%, rgb(79, 79, 233) 100%);
    color: white;
    font-size: 18px;
    border-radius: 5px;
    transition: all .2s;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    margin: 20px 0;
}

.button:hover{
    transform: scale(1.1);
}

.white {
    background-image: none;
    background-color: white;
    color: var(--azul);
}