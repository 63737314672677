@media (max-width: 600px) {
    #form {
        flex-direction: column;
    }
}


#form {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 30px;
    background-color: var(--azul);
}

#form .form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 300px;
    width: 330px;
    max-width: 100%;
    border: 2px solid var(--verde);
    border-radius: 5px;
    color: white;
}

#form .formTitle{
    font-size: 25px;
    text-align: center;
    margin: 20px 0;
    color: var(--verde);
}
#form .input{ 
    background-color: transparent;
    border: none;
    border-bottom: 1px solid gray;
    margin: 10px 0;
}

#form .submit{
    background-color: var(--verde);
    border: 1px solid transparent;
    padding: 5px 20px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 15px;
    margin: 20px 0;
}
#form .submit:hover{
    background-color: transparent;
    border: 1px solid var(--verde);
    color: var(--verde);
}

#form .img{
    width: 400px;
}