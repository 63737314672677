@media (max-width: 600px) {
    #about .text {
        width: 100% !important;
    }
}

#about {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    background-color: var(--azul);
    background-image: url('../../imgs/house2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    position: relative;
}
#about .title{
    display: flex;
    justify-content: center;
    background-color: white;
    color: var(--azul);
    width: 100%;
    padding: 30px;
    font-size: 30px;
    font-weight: bold;
    z-index: 1;
}

#about .container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
    margin: 50px;
    z-index: 1;
}
#about .btn{
    margin: 20px 0;
}

#about .text {
    font-size: 18px;
    font-weight: 500;
    color: white;
    width: 50%;
}

#about .ctaText{
    font-weight: 700;
    font-size: 20px;
}

#about .gradient{
    width: 100%;
    height: 100%;
    position: absolute;
    background-image:  linear-gradient(110deg, var(--azul) 10%, transparent 100%);
}