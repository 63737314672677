.mainTitle{
    font-size: 50px;
    font-weight: 500;
    color: black;
    font-family: 'Poppins', sans-serif !important;
    text-align: center;
}

.white{
    color: white;
}